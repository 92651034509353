import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';

import './preview.scss';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) {
    return (
      <div className="PreviewLoading is-inactive"><p>No preview found.</p></div>
    );
  }

  return (
    <div className="PreviewLoading is-active">
      <p>Loading preview</p>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'isko',
  linkResolver: ({ node, key, link }) => doc => {
    if (doc.type === 'homepage') {
      return `/`;
    }
    if (doc.type === 'header') {
      return `/`;
    }
    if (doc.type === 'footer') {
      return `/`;
    }
    return `/${doc.uid}`;
  }
});
